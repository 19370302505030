$(function(){
    let $galleries = $('.gallery-view-wrap');

    if($galleries.length <= 0) {
        return;
    }

    let light_gallery_settings = {
        thumbnail: true,
        animateThumb: false,
        showThumbByDefault: false,
        selector: '.lg-item:visible',
        download: false
    };

    $galleries.each(function(){
        let $gallery = $(this);
        $gallery.on('click', '.btn-gallery-view', function(e){
            e.preventDefault();
            e.stopPropagation();
            let wrap = $(this).closest('.gallery-view-wrap');
            let data_gallery = wrap.attr('data-gallery');
            if(data_gallery === "hide") {
                wrap.attr('data-gallery', 'view-part');
            } else if(data_gallery === "view-part") {
                wrap.attr('data-gallery', 'view-all');
            } else {
                wrap.attr('data-gallery', 'hide');
            }
            let set_time = setTimeout(function(){
                $gallery.data('lightGallery').destroy(true);
                $gallery.lightGallery(light_gallery_settings);
                clearTimeout(set_time);
            }, 200);
        });
        $gallery.lightGallery(light_gallery_settings);
    });
});

$(function(){
    $('body').on('click', '.btn-video-play', function(){
        let $btn = $(this);
        let $wrap = $btn.closest('.video-box');
        let data_src = $btn.attr('data-src');
        let data_id = "video" + data_src;
        let dom_frame = '<iframe  id="' + data_id + '" src="https://www.youtube.com/embed/' + data_src + '?enablejsapi=1&&rel=0&&showinfo=0" frameborder="0" allowfullscreen></iframe>';
        $wrap.prepend(dom_frame);
        $wrap.find('.video-cover').stop().fadeOut(200);

        let oPlayer = new YT.Player(data_id, {
            events: {
                "onReady": function(){
                    oPlayer.mute();
                    oPlayer.playVideo();
                }
            }
        });
    });
});

window.initMap = function(){
    let $optionsMeta = $('meta[name=map-options]');

    if ($optionsMeta.length <=0) {
        return;
    }

    let mapOptions = JSON.parse($optionsMeta.attr('content'));

    let map = new google.maps.Map(document.getElementById('map'), {
        center: mapOptions.pos_center,
        zoom: mapOptions.zoom,
        scrollwheel: false,
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: [
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {"saturation": 36},
                    {"color": "#969696"},
                    {"lightness": 40}
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {"visibility": "on"},
                    {"color": "#000000"},
                    {"lightness": 16}
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {"visibility": "off"}
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {"lightness": 20}
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {"color": "#000000"},
                    {"lightness": 17},
                    {"weight": 1.2}
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "geometry.stroke",
                "stylers": [
                    {"color": "#606060"}
                ]
            },
            {
                "featureType": "administrative.locality",
                "elementType": "labels.text.fill",
                "stylers": [
                    {"color": "#8d8d8d"}
                ]
            },
            {
                "featureType": "administrative.locality",
                "elementType": "labels.icon",
                "stylers": [
                    {"visibility": "simplified"},
                    {"color": "#9e9e9e"}
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {"visibility": "on"}
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {"color": "#000000"},
                    {"lightness": 20}
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "labels.icon",
                "stylers": [
                    {"saturation": "-100"},
                    {"lightness": "-54"}
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {"color": "#181818"}
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.stroke",
                "stylers": [
                    {"color": "#424242"}
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {"visibility": "on"},
                    {"lightness": "0"},
                    {"saturation": "-100"}
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {"color": "#333333"},
                    {"lightness": 21}
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {"color": "#333333"}
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {"saturation": "-89"},
                    {"lightness": "-55"}
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {"visibility": "off"}
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {"color": "#141414"},
                    {"lightness": 17},
                    {"saturation": "-100"}
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {"color": "#000000"},
                    {"lightness": 29},
                    {"weight": 0.2}
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {"color": "#000000"},
                    {"lightness": 18}
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {"color": "#141414"}
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {"visibility": "off"},
                    {"color": "#453838"}
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {"color": "#000000"},
                    {"lightness": 16}
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {"color": "#141414"}
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [
                    {"visibility": "off"}
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {"color": "#000000"},
                    {"lightness": 19}
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "labels.icon",
                "stylers": [
                    {"visibility": "on"},
                    {"saturation": "-100"},
                    {"lightness": "-51"}
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {"color": "#000000"},
                    {"lightness": 17}
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {"color": "#262f3a"}
                ]
            }
        ],
    });

    new google.maps.Marker({
        position: mapOptions.pos_metro,
        map: map,
        title: mapOptions.title_metro,
        icon: mapOptions.icon_metro
    });

    new google.maps.Marker({
        position: mapOptions.pos_main,
        map: map,
        title: mapOptions.title_main,
        icon: mapOptions.icon_main
    });

    let directionsDisplay = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
            strokeColor: '#8E0608'
        }
    });

    let directionsService = new google.maps.DirectionsService;
    directionsDisplay.setMap(map);
    directionsService.route({
        origin: mapOptions.pos_metro,
        destination: mapOptions.pos_main,
        travelMode: google.maps.TravelMode['WALKING']
    }, function(response, status){
        if(status !== 'OK') {
            return;
        }
        directionsDisplay.setDirections(response);
    });
};